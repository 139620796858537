import { useCallback, useState } from 'react';
import useTimerRef from './useTimerRef';
import useUnmountedRef from './useUnmountedRef';
/**
 * This hook can be used to switch to a different state temporarily and
 * will automatically switch back to the default state after a given interval
 */
export default function useTransientState(initialState) {
    const [state, setState] = useState(initialState);
    const unmountedRef = useUnmountedRef();
    const timerRef = useTimerRef();
    const setTransientState = useCallback((state, duration) => {
        setState(state);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            if (!unmountedRef.current) {
                setState(initialState);
            }
        }, duration);
    }, []);
    return [state, setTransientState];
}
