async function setContent(content, type) {
    const blob = new Blob([content], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    await navigator.clipboard.write(data);
}
const clipboard = {
    setHTML: (html) => {
        return setContent(html, 'text/html');
    },
};
export default clipboard;
