import Quill from 'quill';
import QuillBetterTable from 'quill-better-table';
import 'quill-better-table/dist/quill-better-table.css';
import 'quill/dist/quill.core.css';
import uploadFile from 'src/lib/file-storage/uploadFile';
import { DisplayStyle } from '../attributors/style/Display';
import HyperLink from '../formats/HyperLink';
import Image from '../formats/Image';
import { MathFieldEditor, MathFieldRenderer } from '../formats/MathField';
import ImageUploader from '../modules/image-uploader/ImageUploader';
import LoadingImage from '../modules/image-uploader/LoadingImage';
import PasteLink from '../modules/PasteLink';
import { PaddingStyle } from '../attributors/style/Padding';
import PasteHandler from '../modules/PasteHandler';
Quill.register('attributors/style/display', DisplayStyle);
Quill.register('formats/display', DisplayStyle);
Quill.register('attributors/style/padding', PaddingStyle);
Quill.register('formats/padding', PaddingStyle);
Quill.register('formats/image', Image);
Quill.register('formats/mathFieldEditor', MathFieldEditor);
Quill.register('formats/mathFieldRenderer', MathFieldRenderer);
Quill.register('formats/imageUploading', LoadingImage);
Quill.register('formats/hyperLink', HyperLink);
Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/pasteLink', PasteLink);
Quill.register('modules/pasteHandler', PasteHandler);
Quill.register('modules/better-table', QuillBetterTable, true);
QuillBetterTable.register();
export default function createQuillInstance(editorContainer, options) {
    const formats = [
        'bold',
        'italic',
        'header',
        'indent',
        'list',
        'image',
        HyperLink.blotName,
        // Table formats
        'table-cell-line',
        'table',
        'table-row',
        'table-body',
        'table-col',
        'table-col-group',
        'table-container',
        'table-view',
    ];
    if (options.readOnly) {
        formats.push('background', 'display', 'padding', 'strike', MathFieldRenderer.blotName);
    }
    else {
        formats.push(LoadingImage.blotName, MathFieldEditor.blotName);
    }
    return new Quill(editorContainer, {
        theme: 'snow',
        modules: {
            table: false, // disable table module
            'better-table': {
                operationMenu: {
                    items: {},
                },
            },
            keyboard: {
                bindings: QuillBetterTable.keyboardBindings,
            },
            toolbar: false,
            imageUploader: {
                async upload(file) {
                    const response = await uploadFile({
                        uri: '__UNAVAILABLE__',
                        file,
                        filename: file.name,
                        type: file.type,
                        size: file.size,
                    });
                    if (!response?.data?.publicURL) {
                        throw new Error('Failed to upload image');
                    }
                    return response.data.publicURL;
                },
            },
            pasteLink: true,
            pasteHandler: true,
        },
        formats,
        placeholder: options.placeholder,
    });
}
