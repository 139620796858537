import { addMathFieldWrapper } from '../../utils/mathField';
export default class PasteHandler {
    quill;
    constructor(quill) {
        this.quill = quill;
        const handlePaste = this.handlePaste.bind(this);
        this.quill.root.addEventListener('paste', handlePaste, {
            capture: true,
        });
    }
    normalizeURIList(urlList) {
        return (urlList
            .split(/\r?\n/)
            // Ignore all comments
            .filter(url => url[0] !== '#')
            .join('\n'));
    }
    /**
     * Most of the below code was copied from the original onCapturePaste
     * implementation in the Quill library:
     * https://github.com/slab/quill/blob/main/packages/quill/src/modules/clipboard.ts#L197
     *
     * It has been tweaked to allow pasting images and also to add math field wrapper
     */
    handlePaste(event) {
        if (event.defaultPrevented || !this.quill.isEnabled())
            return;
        event.preventDefault();
        const range = this.quill.getSelection(true);
        if (range == null)
            return;
        if (this.quill.getModule('imageUploader').doesPasteEventContainImage(event)) {
            this.quill.getModule('imageUploader').handlePaste(event);
            return;
        }
        let html = event.clipboardData?.getData('text/html');
        if (html) {
            html = addMathFieldWrapper(html);
        }
        let text = event.clipboardData?.getData('text/plain');
        if (!html && !text) {
            const urlList = event.clipboardData?.getData('text/uri-list');
            if (urlList) {
                text = this.normalizeURIList(urlList);
            }
        }
        if (text) {
            text = addMathFieldWrapper(text);
        }
        this.quill.clipboard.onPaste(range, { html, text });
    }
}
