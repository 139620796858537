import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from 'src/components/10-atoms/layout/Row';
import useIsSuperAdmin from 'src/modules/authentication/hooks/useIsSuperAdmin';
import Typography from 'src/components/10-atoms/typography/Typography';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useTransientState from 'src/hooks/useTransientState';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import clipboard from 'src/lib/clipboard';
const CopyAnswer = ({ answerHTML, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const showCopy = useIsSuperAdmin();
    const [showCopiedMsg, setShowCopiedMsg] = useTransientState(false);
    const handleCopy = useCallbackWithCtx(ctx => {
        clipboard.setHTML(answerHTML);
        setShowCopiedMsg(true, 3000);
    }, {});
    return showCopy ? (React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.sm, bg: theme.palette.background.dark, xAxis: showCopiedMsg ? 'center' : 'space-between', spacing: theme.spacing.standard.md, ...rowProps }, showCopiedMsg ? (React.createElement(Typography, { align: "center", fontWeight: TypographyFontWeight.MEDIUM }, "Answer copied successfully!!")) : (React.createElement(React.Fragment, null,
        React.createElement(Typography, { flex: true }, "Want to reuse the Answer elsewhere ?"),
        React.createElement(FeatherIcon, { name: "copy", color: theme.palette.primary.main, onPress: handleCopy }))))) : null;
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default CopyAnswer;
